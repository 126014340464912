export function envDomainSuffix(url: string) {
  return url
    .replace('//graphisoftid.graphisoft.com', '//graphisoftid-test.graphisoft.com')
    .replace('//id.graphisoft.com', '//id-test.graphisoft.com')
    .replace('//companymanagement.graphisoft.com', '//companymanagement-test.graphisoft.com')
    .replace('//accounts.graphisoft.com', '//accounts-test.graphisoft.com')
    ;
}

export function messageScript(): { url: string, integrity: string, msgEnv: string } {
  return {
    url: 'https://stwebassetstest001.blob.core.windows.net/test/scripts/gsMessage/gsMessage.js',
    integrity: 'sha384-5qB2tTbm4OLvyCuRSNahJXJP2H3YFeCoUIuZxbtZhjfJ4D9J378h800ndRldWj53',
    msgEnv: 'test',
  };
}
